import React from "react"
import Layout from "../../components/layout"
import BannerPage from "../../components/members/banners"

const BannersPage = () => {
  return (
    <Layout>
      <BannerPage />
    </Layout>
  )
}

export default BannersPage
